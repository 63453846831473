export const getEmailUrl = (match) => `mailto:${encodeURIComponent(match.getEmail())}`;
export const getHashtagUrl = (match, service = false, native = false) => {
    const tag = encodeURIComponent(match.getHashtag());
    switch (service) {
        case 'facebook':
            return native ? `fb://hashtag/${tag}` : `https://www.facebook.com/hashtag/${tag}`;
        case 'instagram':
            return native
                ? `instagram://tag?name=${tag}`
                : `https://www.instagram.com/explore/tags/${tag}/`;
        case 'twitter':
            return native ? `twitter://search?query=%23${tag}` : `https://twitter.com/hashtag/${tag}`;
        default:
            return match.getMatchedText();
    }
};
export const getMentionUrl = (match, service = false, native = false) => {
    const username = match.getMention();
    switch (service) {
        case 'instagram':
            return native
                ? `instagram://user?username=${username}`
                : `https://www.instagram.com/${username}/`;
        case 'soundcloud':
            return `https://soundcloud.com/${username}`;
        case 'twitter':
            return native ? `twitter://user?screen_name=${username}` : `https://twitter.com/${username}`;
        default:
            return match.getMatchedText();
    }
};
export const getPhoneUrl = (match, method = 'tel') => {
    const number = match.getNumber();
    switch (method) {
        case 'sms':
        case 'text':
            return `sms:${number}`;
        default:
            return `tel:${number}`;
    }
};
